.Banner{
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 84vh;
    overflow: hidden;
}

.Left{
    position: absolute;
    top: 90px;
    left: 0;
    text-align: start;
    padding: 130px;
}

.head{
    color: #9e9e9e;
    font-size: 18px;
    font-weight: normal;
}
.Left h2{
    color: #ffffff;
    font-weight: normal;
    font-size: 29px;
    max-width: 61%;
}
.background-animation img{
    position: absolute;
    top: 80px;
    right: 90px;
    width: 550px;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 1) 100%);
    z-index: 0;
    animation: pulse 5s infinite alternate;
}
@keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05);
    }
  }

  .contact-btn{
  padding: 10px 38px;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 15px;
  border: 1px solid #9e9e9e;;
  font-weight: normal;
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  }
@media (max-width:1280px) {
    .background-animation img{
        right: 90px;
        width: 450px;
    }
    .Left h2{
        font-size: 21px;
        max-width: 50%;
    }
  
}
@media (max-width:1024px) {
    .background-animation img{
        right: 40px;
        width: 390px;
    }
    .Left h2{
        font-size: 16px;
        max-width: 50%;

    }
    .head{
        font-size: 13px;
    }
    
  .contact-btn{
    padding: 10px 30px;
    margin-top: 20px;
    font-size: 10px;
    }

    
}
@media (max-width:800px) {
    .background-animation img{
        right: 40px;
        width: 320px;
    }
    .Left h2{
        font-size: 12px;
        max-width: 50%;
    }
    .head{
        font-size: 11px;
    }
    
  .contact-btn{
    padding: 10px 25px;
    margin-top: 20px;
    font-size: 9px;
    } 
}
@media (max-width:500px) {

    .background-animation img{
        right: -187px;
        top: 20px;
        width: 520px;
        display: grid;
        align-items: end;
        position: fixed;
    }
   
    .Banner{
        display: block;
    }
    .Left{
        position: fixed;
        top: 510px;
        left: 43px;
        text-align: start;
        padding: 0px;
    }
    .Left h2{
        font-size: 12px;
        max-width: 395px;

    }
    .head{
        font-size: 11px;
    }
    
  .contact-btn{
    padding: 10px 30px;
    margin-top: 10px;
    font-size: 10px;
    }
}

  