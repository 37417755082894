/* .NavBar{
    background-color: 	black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #5b6072;
}  */
 .sidenav{
    display: block;
}
.new-dropdown{
    display: block;
    width: 25px;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }
  .overlay-content{
     position: relative;
     top: 25%;
     width: 100%;
     text-align: center;
     margin-top: 30px;      
}
  .sidenav li {
  padding: 8px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: normal;
  font-size: 36px;
  color:#9e9e9e;
  display: block;
  transition: 0.3s;
  }
  .list-icon a{
    display: none;
  }
  .nav-email{
    display: none;
  }
  
  /* .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    cursor: pointer;
    
  }   */

  .closebtn{
    position: absolute;
    top: 0;
    left: 15px;
    font-size: 50px;
    font-weight: normal;
    text-decoration: none;
    color: #ffffff;
  }
  .mySidenav{
    width :100%;
    
}
.small-menu-links{
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}
.small-menu-links a {
  color:#9e9e9e ;
  text-decoration: none;

}
.sidenav a:hover {
    color: #f1f1f1;
  }

.nav-email a{
  display: flex;
}
.nav-email i{
 margin-top: 15px;
}
  
@media (max-width:800px) {
    .sidenav li {font-size: 25px}
  .sidenav .closebtn {
  font-size: 40px;
  top: 15px;  
}
.small-menu-links{
  width: 80%;
}
}
@media (max-width:500px) {
    .sidenav li {font-size: 20px}
  .sidenav .closebtn {
  font-size: 40px;
  top: 15px;  
}
.small-menu-links{
  width: 80%;
}

.email{
  display: flex; 
  gap: 6px;
  align-items: center;
  overflow: hidden;
}
.email h1{
  font-size: 14px;
  font-weight: normal;
}
.icons{
  text-transform: uppercase;
  text-decoration: none;
  font-weight: normal;
  font-size: 36px;
  color: #9e9e9e;
  display: flex;
  transition: 0.3s;
  position: relative;
  gap: 10px;
  left: 122px;
}
.icons a{
  color:#9e9e9e;
}
.list-icon{

  margin-left: 11px;
}

.list-icon a{
  display: contents;
  letter-spacing: 7px;
}
.list-icon a{
  color:#9e9e9e;
}
.nav-email{
  display: flex;
  align-items: center;
  color: #9e9e9e;
  position: absolute;
  top: 410px;
  left: 150px;
  text-transform: none;
}
.nav-email h1{
  font-size: 15px;
  padding: 5px;
}
}