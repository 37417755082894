.Skills h1{
    padding-left: 80px;
    padding-top: 44px;
    font-weight: normal;
}
.skill-container{
    display: flex;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
    margin-top: -50px;
    padding: 30px;
    gap: 17%;
}
.left-side h2{
    color: #ffffff;
    font-weight: normal;
    max-width: 500px;
    font-size: 35px;
}
.Skills-points{
    color: #ffffff;
    font-weight: normal;
    font-size: 15px;
    padding-left: 25px;
    padding-top: 10px;
}
.icon-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 40px;
    padding-top: 55px;
    justify-items: center;
    align-items: center;
  }
  
  .icon-grid img {
    width: 60px;
    height: 60px;
    border-radius: 50%; 
    object-fit: cover;
  }
  .AD-section{
  color: #fff;
  }
  .advantage {
    text-align: center;
    width: 80%;
  }
  .advantage h3{
    font-size: 15px;
    font-weight: normal;
  }
  .advantage p{
    font-size: 12px;
    color: #9e9e9e;
    font-weight: normal;
}
  .advantage-container {
    display: flex;
    justify-content: space-around;
    margin: 30px;
    margin-bottom: 30px;
  }
  .advantage {
    background-color: #000000;
    border: 1px solid #9e9e9e;
    padding: 20px;
    width: 23%;
    text-align: center;
  }
  .advantage:hover{
    border: 2px solid #ffffff;
  }
  .advantage img {
    width: 30px;
    margin-bottom: 10px;
  }
  .confidentiality{
    display: flex;
    margin-left: 80px;
    margin-bottom: 10px;
  }
  .confidentiality-guarantee {
    margin-top: 20px;
    font-size: 10px;
    color: #9e9e9e;
    font-weight: normal;
  }
  
  .confidentiality img{
    width: 20px;
    height: 20px;
    margin-top: 17px;
    margin-right: 5px;
  }

  @media (max-width:1286px) {
  .Skills h1{
    padding-left: 50px;
}
.left-side h2{
  font-size: 33px;
  max-width: none;
  margin-left: 18px;
}
.skill-container{
  padding: 30px;
  gap: 9%;
}
.Skills-points{
  font-size: 13px;
  padding-top: 10px;
  padding-left: 39px;
}
.icon-grid img {
  width: 55px;
  height: 55px;
}
.advantage-container {
  justify-content: space-around;
  margin: 10px;
  margin-bottom: 0px;
}
    
}
@media (max-width:1050px) {
  .Skills h1{
    padding-left: 35px;
}
.skill-container{
  padding: 30px;
  gap: 15%;
}
.left-side h2{
  font-size: 30px;
  margin-left: 5px;
}
.Skills-points{
  font-size: 13px;
  padding-top: 10px;
  margin-left: -10px;
}
.icon-grid img {
  width: 40px;
  height: 40px;
}
.advantage-container {
  margin-left: 38px;
  gap: 12px;
  margin-right: 38px;
}
.advantage {
  width: 27%;
}
.advantage img {
  width: 25px;
  margin-bottom: 0px;
}
.advantage h3{
  font-size: 13px;
}
.advantage p{
  font-size: 10px;
}
.confidentiality{
  margin-left: 17px;
  margin-bottom: 10px;
}
.confidentiality-guarantee {
  font-size: 9px;
}

.confidentiality img{
  width: 16px;
  height: 16px;
}

  
}
@media (max-width:860px) {
  .skill-container{
    display: block;
    justify-items: normal;
}
.left-side h2{
  max-width: 500px;
  font-size: 29px;
}
.Skills-points{
  font-size: 13px;
  padding-left: 36px;
  padding-top: 10px;
}
.advantage-container {
  margin-left: 38px;
  gap: 12px;
  margin-right: 38px;
}
.advantage {
  padding-bottom: 0px;
}
.advantage img {
  width: 20px;
  margin-bottom: 0px;
}
.advantage h3{
  font-size: 10px;
}
.advantage p{
  font-size: 8px;
}
.confidentiality{
  margin-left: 29px;
  margin-bottom: 10px;
}
.confidentiality-guarantee {
  font-size: 7px;
}

.confidentiality img{
  width: 15px;
  height: 15px;
}
}
@media (max-width:500px) {
  .Skills h1{
    padding-left: 0px;
}
  .skill-container{
    display: block;
    padding: 0px;
    margin-top: 0px;
}
.left-side h2{
  max-width: 330px;
  font-size: 18px;
  margin-left: 0px;
}
.Skills-points{
  font-size: 9px;
  padding-left: 25px;
  padding-top: 0px;
}
.icon-grid {
  display: grid;
  margin-right: -24px;
  gap: 15px;
  justify-items: normal;
  padding-top: 5px;
}

.icon-grid img {
  width: 40px;
  height: 40px;
}
.advantage-container {
  display: block;
  margin: 0px;
  margin-bottom:0px
}
.advantage {
    padding: 10px 0px 11px 0px;
    width: 100%;
    margin-bottom: 10px;
}
.advantage img {
  width: 16px;
  margin-bottom: 0px;
}
.advantage h3{
  font-size: 8px;
}
.advantage p{
  font-size: 7px;
}
.confidentiality{
  margin-left: 0px;
  margin-bottom: 0px;
}
.confidentiality-guarantee {
  font-size: 6px;
}

.confidentiality img{
  width: 13px;
  height: 13px;
}

   
}

  