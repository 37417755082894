.ErrorPage{
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 84vh;
    overflow: hidden;
}
.Left-C{
    position: absolute;
    top: 40px;
    left: 0;
    text-align: start;
    padding: 130px;
    color: #ffffff;
}
.number{
    color: #ffffff;
    font-size: 150px;
    font-family: "Poppins", sans-serif;
    margin-bottom: -55px;
    font-weight: normal;
}
.dd-cc{
    color: #9e9e9e;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 30px;
    margin-left: 20px;
}
.background-img img{
    display: grid;
   align-items: end;
    position: fixed;
    right: -381px;
    top: -129px;
    width: 1001px;
    z-index: -1;
}
.Error-btn{
    padding: 15px 35px;
    margin-top: 0px;
    margin-left: 24px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 17px;
    border: 1px solid #9e9e9e;
    font-weight: normal;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

@media (max-width:1280px) {
    .background-img img{
        right: -410px;
    }
   
  }
@media (max-width:1024px) {
    .Left-C{
        padding: 90px;
        top: 100px;
    }
    .number{
        font-size: 100px;
        margin-bottom: -33px;
    }
    .dd-cc{
        font-size: 21px;
        margin-left: 12px;
    }
    .background-img img{
        display: grid;
        align-items: end;
        position: fixed;
        right: -500px;
        top: -129px;
        width: 986px;
    }
    .Error-btn{
        padding: 15px 20px;
        margin-top: 0px;
        margin-left: 12px;
        font-size: 13px;
    }
   
  }
@media (max-width:800px) {
   .Left-C{
        top: 140px;
    } 
    .number{
        font-size: 80px;
        margin-bottom: -33px;
    }
    .dd-cc{
        font-size: 17px;
        margin-left: 10px;
    }
    .background-img img{
        right: -500px;
        top: -129px;
        width: 986px;
    }
    .Error-btn{
        padding: 15px 20px;
        margin-top: 0px;
        margin-left: 12px;
        font-size: 9px;
    
    }
   
  }
@media (max-width:600px) {
   .Left-C{
    top: 400px;
    padding: 47px;
    } 
    .number{
        font-size: 65px;
        margin-bottom: -25px;
    }
    .dd-cc{
        font-size: 14px;
        margin-left: 5px;
    }
    .background-img img{
        right: -633px;
        top: -138px;
        width: 1000px;
    }
    .Error-btn{
        padding: 13px;
        margin-top: 0px;
        width: 110px;
        margin-left: 5px;
        font-size: 8px;
    
    }
   
  }
