.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: (0, 0, 0, 0.9); /* Optional overlay color */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
/* HTML: <div class="loader"></div> */
.loader {
    width: 80px;
    aspect-ratio: 1;
    border: 10px solid #000;
    box-sizing: border-box;
    background: 
      radial-gradient(farthest-side at right,#fff 98%,#0000) 20% 50%/40% 80%,
      radial-gradient(farthest-side at left ,#fff 98%,#0000) 80% 50%/40% 80%,
      #000;
    background-repeat: no-repeat;
    filter: blur(4px) contrast(10); 
    animation: l18 0.8s infinite alternate;
  }
  @keyframes l18 {
    0%,20% {background-position:20% 50%,80% 50%}
    100%   {background-position:80% 50%,20% 50%}
  }