
/* .Contact{
    color: #ffffff;
    
} */
.contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 100px;
    color: #ffffff;
  }
.contact-info {
    flex: 1;
    padding: 10px;
    max-width: 500px;
    /* padding-right: 35%; */
}
.contact-info h1{
    margin-bottom: 40px;
}
.contact-info p {
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 30px;
}
.contact-form {
    flex: 1;
    padding-top: 75px;
    padding-right: 100px;
  max-width: 450px;
}
/* .contact-info h2, 
.contact-info p {
    width: 500px; 
} */

/* .contact-form form {
    display: flex;
    flex-direction: column;
} */
.contact-form input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    color: #9e9e9e;
    width: 500px;
    text-align: left;
    padding: 10px;
    margin-bottom: 20px;
}
.contact-form textarea{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    color: #9e9e9e;
    width: 500px;
    height: 100px;
    margin-bottom: 20px;
    text-align: left;
    padding: 10px;
    padding-top: 10px;
    resize: none;
}

.contact-form button {
    display: block;
    margin-top: 20px;
    letter-spacing: 1px;
    font-size: 13px;
    width: 200px;
    margin-left: 320px;
    text-transform: uppercase;
    padding: 10px;
    font-family: "Space Mono", system-ui;
    border: 1px solid #9e9e9e;
    font-weight: normal;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #444;
    color: #fff;
}
.contact-form input:focus, 
.contact-form textarea:focus {
    background-color: transparent;
    outline: none; 
}
@media (max-width:1280px) {
    .contact-container {
        padding: 75px;
      }
      .contact-info {
        padding: 0px;
    }
    .contact-info p {
        font-size: 15px;
        max-width: 400px;
        margin-bottom: 30px;
    }
    .contact-form input {
        width: 450px;
        margin-bottom: 20px;
    }
    .contact-form textarea{
        width: 450px;
        padding: 10px;
    }
    .contact-form {
        /* flex: 1;
        padding-top: 75px; */
        padding-top: 60px;
        padding-right: 35px;
    }
    .contact-form button {
        font-size: 12px;
        width: 175px;
        margin-left: 300px;
    }
    
    
}
@media (max-width:1025px) {
    .contact-container {
        padding: 30px;
        margin-bottom: 100px;
      }
    .contact-info p {
        font-size: 13px;
        max-width: 350px;
        margin-bottom: 30px;
    }
    .contact-form input {
        width: 400px;
        margin-bottom: 20px;
    }
    .contact-form textarea{
        width: 400px;
        padding: 10px;
    }
    .contact-form {
        /* flex: 1;
        padding-top: 75px; */
        padding-right: 0px;
    }
    .contact-form button {
        font-size: 11px;
        width: 146px;
        margin-left: 280px;
    }
    
    
}
@media (max-width:800px) {
    .contact-container {
        padding: 0px;
        margin-top: 50px;
        margin-bottom: 100px;
      }
    .contact-info p {
        font-size: 12px;
        max-width: 265px;
        margin-bottom: 27px;
    }
    .contact-form input {
        width: 300px;
        margin-bottom: 20px;
    }
    .contact-form textarea{
        width: 300px;
        padding: 10px;
    }
    .contact-form {
        padding-right: 0px;
    }
    .contact-form button {
        font-size: 11px;
        width: 146px;
        margin-left: 178px;
    }
    
    
}
@media (max-width:600px) {
    .contact-container {
        display: block;
        padding: 0px;
        margin-top: 50px;
        margin-bottom: 100px;
      }
    .contact-info p {
        font-size: 11px;
        max-width: 500px;
        margin-bottom: 14px;
    }
    .contact-form input {
        width: 435px;
        max-width: 95%;
        padding: 5px;
        margin-bottom: 20px;
    }
    .contact-form textarea{
        width: 435px;
        max-width: 95%;
        padding: 5px;
        margin-bottom: 20px;
    }
    .contact-form {
        padding-top: 20px;
        padding-right: 0px;
    }
    .contact-form button {
        font-size: 10px;
        width: 145px;
        margin-left: 0px;
    }
    
    
}